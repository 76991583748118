
.flag{
   height:32px;
}

.main{
    margin-left:3px;
}



.gfg { 
    margin: 3%; 
    position: relative; 
} 

.first-txt { 
    position: absolute; 
    top: 1px; 
    left: 1px; 
} 

.second-txt { 
    position: absolute; 
    top: 1px; 
    left: 1px; 
} 