  .bubblingG {
    text-align: center;
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
  }
  .bubblingG span {
    width: 5px;
    height: 5px;
    background: rgb(239, 252, 243);
    border-radius: 24px;
    animation: bubblingG 0.9s infinite alternate;
  }
  .bubblingG > div:nth-child(even) {
    margin: 0 2px;
  }
  #bubblingG_1 {
    animation-delay: 0s;
  }
  #bubblingG_2 {
    animation-delay: 0.27s;
  }
  #bubblingG_3 {
    animation-delay: 0.54s;
  }
  @keyframes bubblingG {
    0% {
      width: 5px;
      height: 5px;
      background-color: #276ecb;
      transform: translateY(0);
    }
    100% {
      width: 11px;
      height: 11px;
      background-color: #3280e6;
      transform: translateY(-10px);
    }
  }
  .bubbleLoad {
    height: 32px;
    width: 73px;
    padding: 20px 10px 15px 20px;
  }

  .chat-msg-hygeia{
    padding:6px;
    clear: both;
  }
  
  .chat-msg-hygeia > .msg-avatar img {
    height: 80px;
    width: 80px;
    float: left;
  }
  
  .cm-msg-text-container{
    
    color: #666;
    max-width: 75%;
    float: left;
    margin-top: 2px;
  }
  
  .cm-msg-text{
    background: white;
    color: #666;
    width: 100%;
    margin-top: 4px;
    border-radius: 10px;
    min-height: 42px;
    padding: 8px;
  }

  .top-avatar{
    height: 100px;
    width: 100px;
  }