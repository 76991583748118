/* Custom Stylesheet */
/**
 * Use this file to override Materialize files so you can update
 * the core Materialize files in the future
 *
 * Made By MaterializeCSS.com
 */

 body{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 }

.icon-block {
  padding: 0 15px;
}
.icon-block .material-icons {
	font-size: inherit;
}

nav {
  color:rgba(47, 97, 133, 0.836);
  background-color: white;
  width: 100%;
  height: 68px;
  line-height: 66px;
  
}

nav a {
  color:rgba(47, 97, 133, 0.836);
  font-weight: bold;
}

nav .brand-logo {
  position: absolute;
  color:rgba(47, 97, 133, 0.836);
  display: inline-block;
  font-size: 2.1rem;
  padding-top: 8px;
}

nav ul a {
  transition: background-color .3s;
  font-size: 1rem;
  color:rgba(47, 97, 133, 0.836);
  display: block;
  padding: 0 15px;
  cursor: pointer;
  font-weight: bold;
}

.dropdown-content li > a, .dropdown-content li > span {
  font-size: 1rem;
  color:rgba(47, 97, 133, 0.836);
  display: block;
  line-height: 22px;
  padding: 14px 16px;
}

.breadcrumb {
  font-size: 18px;
  color: rgba(57, 139, 82, 0.863);
}

.breadcrumb i,
.breadcrumb [class^="mdi-"], .breadcrumb [class*="mdi-"],
.breadcrumb i.material-icons {
  display: inline-block;
  float: left;
  font-size: 24px;
}

.breadcrumb:before {
  content: '\E5CC';
  color: rgba(81, 194, 115, 0.7);
  vertical-align: top;
  display: inline-block;
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 25px;
  margin: 0 10px 0 8px;
  -webkit-font-smoothing: antialiased;
}

.breadcrumb:first-child:before {
  display: none;
}

.breadcrumb:last-child {
  color: rgba(81, 194, 115, 0.507);
}


.hygeiabackground{
  background: rgba(199, 238, 210, 0.26);
  
}

.hygeiabackgroundfooter{
  background: rgba(99, 121, 119, 0.5);
}

.hygeiaborder{
  color:  rgba(57, 108, 145, 0.582);
}

.hygeiaborder{
  color:  rgba(57, 108, 145, 0.582);
}

.hygeialineheader{
  background-color:  rgb(29, 163, 36);
  height: 5px;
  width:100%;
 
}

.hygeiawithshadow{
  text-shadow: 1px 1px 2px rgb(203, 212, 206);

  color: rgb(12, 150, 30);
  font-weight: 900;
}


.tabs .tab a:focus, .tabs .tab a:focus.active {
  background-color: rgba(246, 178, 181, 0.2);
  outline: none;
}
.tabs .tab a:hover, .tabs .tab a.active {
  background-color: transparent;
  color: #10500e;
}
.tabs .tab a {
  color: rgb(135 182 216);
  display: block;
  width: 100%;
  height: 100%;
  padding: 0 24px;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: color .28s ease, background-color .28s ease;
}






.underline {
  text-decoration: underline;
}

.italic {
  font-style: italic;
}

.underline-inline-block {
  text-decoration: underline;
  display: inline-block;
}

.emphasize {
  text-decoration: underline;
  /*font-style: italic;*/
  font-weight: bold;
  margin-left: 4px;
}

@media only screen and (min-width: 601px) {
  .mobile-br {
    display: none;
  }
  .padding-right-on-med-and-up {
    padding-right: 17px;
  }
  .indent-left-on-med-and-up {
  padding-left: 12px;
}
}

@media only screen and (max-width: 600px) {
  .mobile-br {
    display: block; /* !important */
  }
}

.border-2 {
  border: 2px solid black;
  padding: 0 10px;
}

.border-4 {
  border: 4px solid black;
  padding: 0 10px;
}

.select-surgery {
  font-weight: bold;
  text-decoration: underline;
}

.select-review {
  font-weight: bold;
  color: #226e93;
}

.indent {
  margin-left: 4px;
}

.indent-left {
  padding-left: 12px;
}

p label [type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: static;
 }
 
 .flex {
  display: flex;
  flex-wrap: wrap;
}

.border-b {
  border-bottom: 1px solid black;
}

.heading-review::before {
  content: "*"
}

.blank-line::before {
  content: "\00a0";
 }

.heading-review {
  font-weight: bold;
  border-bottom: 1px solid black;
}

.list-review li {
  border-bottom: 1px solid black;
 }



.row{
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;

}


.tabs-icon {
  font-size: 10px;
  overflow: hidden;
}

.tabs-icon i {
  display: block;
  margin-top: 5px;
  margin-bottom: -15px;
  font-size: 24px;
}

.tab {
  background-color: #c6fada85;
}

.tabs .tab a:hover, .tabs .tab a.active {
  background-color:rgba(168, 238, 189, 0.863);
  color: #3e9462d3;
  color: #2a7549;
}

.tabs .tab a {
  color: rgb(81, 194, 115);
  display: block;
  width: 100%;
  height: 100%;
  padding: 0 24px;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: color .28s ease, background-color .28s ease;
}

.tabs .tab a {
  color: rgb(81 194 115 / 70%);
  display: block;
  width: 100%;
  height: 100%;
  padding: 0 24px;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: color .28s ease, background-color .28s ease;
}

.tabs .indicator {
  position: absolute;
  bottom: 0;
  height: 3px;
  background-color: #1a7c37;
  will-change: left, right;
}

.tabs .tab a:focus, .tabs .tab a:focus.active {
  background-color: rgb(81 194 115 / 20%);
  outline: none;
}

.custombutton {
  color: rgba(31, 107, 47, 0.836);
  display: block;
  line-height: 22px;
  padding: 14px 16px;
  font-size: medium;
  font-weight: bold;
  cursor: pointer;
}

.button {
  border-radius: 50px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
  -moz-border-radius: 50px;
  -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
  -webkit-border-radius: 50px;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
  color: #fff;
  cursor: pointer;
  float: center;
  font-size: 18px;
  font-weight: bold;
  margin-right: 20px;
  padding: 10px 20px;
  text-decoration: none;
  text-transform:none;
  height: 40px;
  line-height: 20px;
}

.smallbutton {
  text-align: center;
  color: #ffffff;
  /*font-size: 16px;*/
  box-shadow: 5px 10px 8px #888888;
  border: 0px #276ecb solid;
  background-color: #23b241;
  border-radius: 10px;
  cursor: pointer;
  word-wrap: break-word;
  border: 1px solid #ccc;
  line-height: 25px;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.smallbuttonM {
  text-align: center;
  color: #ffffff;
  /*font-size: 16px;*/
  box-shadow: 5px 10px 8px #888888;
  border: 0px #276ecb solid;
  background-color: #23b241;
  border-radius: 10px;
  cursor: pointer;
  word-wrap: break-word;
  border: 1px solid #ccc;
  line-height: 25px;
  
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.button:hover {
  color: #fff;
  text-decoration: none;
  background:-moz-linear-gradient(center top , #4d9604, #56a308);
  background: -webkit-gradient(linear, center top, center bottom, from(#62b60e), to(#5aa50f));
}

.red {
  background:-moz-linear-gradient(center top , #f40000, #cc0000);
  background: -webkit-gradient(linear, center top, center bottom, from(#f40000), to(#cc0000));
}

.green {
  background: #1e462d;
}

.blue {
  background:-moz-linear-gradient(center top ,#3585ee, #1f60b6);
  background: -webkit-gradient(linear, center top, center bottom, from(#3585ee), to(#1f60b6));
}

.gray {
  background:-moz-linear-gradient(center top ,#bfc1c4, #7d7e80);
  background: -webkit-gradient(linear, center top, center bottom, from(#8f9091), to(#a1a2a3));
}

.hygeiafont{
  
}

.hygeiafontH1{
  font-size: calc(0.75em + 1vmin);
  
}

.hygeiafontH2{
  font-size: calc(1.50em + 1vmin);
  color:#0a631d;
  font-weight: bold;
}

.hygeiafontsmall{
  font-size: calc(0.50em + 1vmin);
  font-weight: bold;
}

.hygeiafooter {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 40px;
  background-color: rgb(247, 247, 247);
  text-align: center;
}

.select-wrapper {
	position: relative;
}
.select-wrapper select {
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 10;
	opacity: 0;
}

@media only screen and (min-width: 601px) {
  .customScreen {
    width: 25%;
    justify-content: center; 
    display: flex; 
    text-align:center;
  }
}

@media only screen and (min-width: 600px) {
  .customScreen {
    width: 100%;
    justify-content: center; 
    display: flex; 
    text-align:center;

  }
}

.hygeiafontH3{
  font-size: calc(0.15em + 1vmin);
  font-weight: bold;
}

.flex-container {
  display: flex;
  justify-content: center;
}

.flex-container > div {
  width: 80px;
  margin: 5px;
  text-align: center;
  line-height: 75px;
  font-size: 30px;
}

.flex-container1 {
  display: flex;
  justify-content: center;

}

.flex-container1 > div {
  width: 180px;
  margin: 5px;
  text-align: center;
  line-height: 75px;
}

.flex-container2 {
  display: flex;
  justify-content: center;
}

.flex-container2 > div {
  width: 100px;
  margin: 5px;
  text-align: center;
  line-height: 75px;
}

.flex-container3 {
  padding:10px;
  
  display: flex;
  justify-content: center;
  margin:10px;
}

.flex-container3 > div {
  margin:10px;
  min-width: 120px;
  text-align: center;
  
}

.flex-container3 > div > a {
  margin:10px;
  color: white;
  text-align: center;
  line-height: 28px;
}

.suffix {
  position: absolute;
  -webkit-transition: color .2s;
  transition: color .2s;
  top: 2.5rem;
  right: 0;
}

.modal .modal-footer {
  border-radius: 0 0 2px 2px;
  background-color: #fafafa;
  padding: 4px 6px;
  height: 56px;
  width: 100%;
  text-align: right;
  margin-bottom: 20px;
}