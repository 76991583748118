
/* label span {
    padding-left: 30px !important;
    padding-right: 30px !important;
}

input {
    height: 3rem;
} */


  table.GeneratedTable {
    width:100%;
    background-color: #ffffff;
    border-collapse: collapse;
    color: #302e2e;
    border-collapse: collapse;
    
  }
  
  
  tr{
    border-width: 1px;
    border-color: #9cc99c;
    border-style: solid;
  }
 
  td, th{
    font-size:small;
    padding: 5px;
    border-right: 1px solid #9cc99c;
    border-left: 1px solid #9cc99c;
    
  }
  
  .paperdiv {
    background: white;
    display: block;
    margin: 0 auto;
    margin-bottom: 0.5cm;
    padding: 10px;
  }

  .papercontainer {
    
    overflow: auto;
    background: white;
    display: flex;
   
  }

  .tdspacer{
      background: #f3fdf1;
      
  }

  .trspacer{
    background: #f3fdf1;
    page-break-inside: always!important;
  }

  tr, th { 
    page-break-inside: avoid;
  }

  thead { display: table-header-group; } 

  tr { page-break-inside: avoid; }
  
  @font-face {
    font-family: "Hurricane";
    src: local("Hurricane"),
    url("../../fonts/Hurricane-Regular.ttf") format("truetype");
    font-weight: bold;
  }

  .signature {
    font-family: "Hurricane";
    font-size: 42px;
   }

   

  /* .modal .modal-content { */
    
    
    /* Firefox */
    /* WebKit */
    /* height: -webkit-calc(100% - 270px); */
    /* Opera */
    /* height: -o-calc(100% - 270px); */
    /* Standard */
    /* height: calc(100% - 270px); */ 
  /* }  */
  

  /* .modal .modal-footer {
    border-radius: 0 0 2px 2px;
    background-color: #fafafa;
    padding: 4px 6px;
    height: 6px;
    width: 100%;
    text-align: right;
    max-height: 5px;
  }

  .modal.modal-fixed-footer .modal-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    position: static;
    bottom: 0;
  } */
  
  .modal.custom .modal-footer  {
  
    padding-top:15px;
    padding-right: 10px;
    /* text-align: left; */
    /* bottom: 0; */
  }

  
.medicalAnchor{
  margin: 10px;
}
  
  .signatureFooter {
    page-break-inside: avoid;
  }

.modalHygeiaBackground{
  
  position:fixed;
  right:0;
  top:0;
  height: calc(100% - 0px);
  width: 100%;
  background-color: rgb(217, 247, 229);
  z-index:1041;
  overflow: hidden;
}

.modalHygeiaDialog {
  margin:15px;
  width: 98%;
  height: 98%;
 
  overflow: auto;
  padding: 15px;
  border: 2px solid rgb(182, 236, 187);
}

.pageBorderTop{
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  width:615px;
}

.documentContainer{
  left: 25%;
}

.customTd{
  font-size:small;
  padding: 5px;
  border-right: 1px solid #9cc99c;
  border-left: 1px solid #9cc99c; 
  page-break-inside:avoid;
  page-break-after:auto;
}

.customTr{ 
  page-break-inside:avoid; 
  page-break-after:auto;
  
}

@media print {
  
}

.avoidBreak { 
  border: 2px solid;
  page-break-inside:avoid;
}

table  { page-break-inside:auto }
td div { page-break-inside:avoid; page-break-after: auto;} /* This is the key */
td { page-break-inside:avoid; page-break-after: auto; }
tr td div{ page-break-inside:avoid; page-break-after: auto; }
thead  { display:table-header-group }
tfoot  { display:table-footer-group }
div { page-break-inside:avoid!important; page-break-after: auto!important;} /* This is the key */
