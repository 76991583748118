
.flag{
   height:32px;

}

.main{
    margin-left:3px;
}

.container1 {
    display: flex;
    flex-direction: row;            /* default value; can be omitted */
    flex-wrap: nowrap;              /* default value; can be omitted */
    margin-left: 3px;
    margin-right: 3px;
    
}

.container1 > div {
    background-color: transparent;
    margin-top:0px;
    
}

.container1 > img {
    background-color: transparent;
    margin-top:0px;
}

.container1 > input {
    background-color: transparent;
    overflow: hidden;
   
}

