* {
  box-sizing: border-box;
}

@media screen and (min-width: 601px) {
  .font1 {
    font-size: 20px;
  }
}

@media screen and (max-width: 600px) {
  .font1 {
    font-size: 14px;
  }
}