.flex-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
}



.sm-button {
    border-radius: 50px!important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
    -moz-border-radius: 50px;
    -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
    -webkit-border-radius: 50px;
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
    color: #fff;
    cursor: pointer;
    font-size: 12px!important;
    font-weight: bold!important;
    width: 180px!important;
    height: 30px;
    line-height: 26px!important;
}

@media only screen and (max-width: 414px) {
    /* smartphones, iPhone, portrait 480x320 phones */
    .flex-container  {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .flex-container > div {
        margin: 5px;
        text-align: center;
    }
    .sm-button {
       
        
        color: #fff;
        cursor: pointer;
        font-size: 12px!important;
        font-weight: bold!important;
        width: 180px;
        height: 30px;
        line-height: 26px!important;
        word-wrap: break-word;

        text-align: center;
       
        
        box-shadow: 5px 10px 8px #888888;
    
        background-color: #23b241;
        border-radius: 10px;
        cursor: pointer;
 
        border: 1px solid #ccc;
    }
}
    
.red {
    background:-moz-linear-gradient(center top , #f40000, #cc0000);
    background: -webkit-gradient(linear, center top, center bottom, from(#f40000), to(#cc0000));
}
  
.green {
    background:-moz-linear-gradient(center top , #78f000, #66cc00);
    background: -webkit-gradient(linear, center top, center bottom, from(#78f000), to(#66cc00));
}
  
.blue {
    background:-moz-linear-gradient(center top ,#3585ee, #1f60b6);
    background: -webkit-gradient(linear, center top, center bottom, from(#3585ee), to(#1f60b6));
}
  
.gray {
    background:-moz-linear-gradient(center top ,#bfc1c4, #7d7e80);
    background: -webkit-gradient(linear, center top, center bottom, from(#8f9091), to(#a1a2a3));
}
  