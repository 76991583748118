* {
  box-sizing: border-box;
}

.bubblingG {
  text-align: center;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.bubblingG span {
  width: 5px;
  height: 5px;
  background: rgb(239, 252, 243);
  border-radius: 24px;
  animation: bubblingG 0.9s infinite alternate;
}
.bubblingG > div:nth-child(even) {
  margin: 0 2px;
}
#bubblingG_1 {
  animation-delay: 0s;
}
#bubblingG_2 {
  animation-delay: 0.27s;
}
#bubblingG_3 {
  animation-delay: 0.54s;
}
@keyframes bubblingG {
  0% {
    width: 5px;
    height: 5px;
    background-color: #276ecb;
    transform: translateY(0);
  }
  100% {
    width: 11px;
    height: 11px;
    background-color: #3280e6;
    transform: translateY(-10px);
  }
}
.bubbleLoad {
  height: 32px;
  width: 73px;
  padding: 20px 10px 15px 20px;
}

.main {
  display: none;
  position: fixed;
  box-shadow: 0px 5px 35px 9px #ccc;
  right: 30px;
  bottom: 30px;
  z-index: 2147483639;
  overflow: hidden;
  border: 1px solid rgb(215, 231, 212);
}

section {
  background: rgb(239, 253, 244);
  margin-right: 4px;
  padding:4px;
  
 
}

header {
  
  height: 60px; 
  border-bottom: 1px solid #ccc;
}

.body {
  overflow-y: scroll;
}

footer {
  height: 60px; 
}

@media only screen and (max-width: 760px) {
  
  .main {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2147483639;
    width: 100%;
  }
  
}


.hygeia-label{
  color: #8f8888;
  font-size: 10px;
}

.chat-msg-hygeia{
  padding:6px;
  clear: both;
}

.chat-msg-hygeia > .msg-avatar img {
  height: 80px;
  width: 80px;
  float: left;

}

.avatar-spacer {
  height: 80px;
  width: 80px;
  float: left;
}

.cm-msg-text-container{
  
  color: #666;
  
  float: left;
  margin-top: 2px;
}

.cm-msg-text{
  background: white;
  color: #666;
  
  margin-top: 4px;
  border-radius: 10px;
  min-height: 42px;
  padding: 8px;
}

.chat-msg-client{
  padding:6px;
  clear: both;
}

.cm-msg-text-client-container{
  color: #666;
  max-width: 75%;
  float: right;
  margin-top: 2px;
}

.cm-msg-text-client{
  background: rgba(199, 238, 199, 0.644);
  padding: 4px;
  color: rgb(88, 85, 85);
  width: 100%;
  float: right;
  border-radius: 10px;
  min-height: 42px;
  
}

.undo-pen{
  color:rgb(134, 127, 127);
  float: right;
  cursor: pointer;
}

/*FOOTER START*/

.button-footer{
  background-color: #379c4d;
  height: 50px;
 
  margin: 2px;
  padding: 4px;
  padding-top: 14px;
  padding-right: 10px;
  padding-left: 10px;
  color: white;
  cursor: pointer;
}

.main-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.center-content {
  /* Lets middle column shrink/grow to available width */
  flex: 1 1 auto;
  padding-left: 5px;
  padding-right: 5px;
}

.left-content,
.right-content {
  /* Forces side columns to stay same width */
  flex: 0 0 50px;
}

/*HEADER START*/

  .image-header > img{ 
    /* flex:1 1 auto; */
    
    margin-top: 4px;
    height: 48px;
    width: 260px;
  }
  .header-menu-container{
    float:right;
    padding-top:16px;
    height:40px;
    max-height: 40px;
    color:green;
    
  }
	.item1 { 
    font-size: 16px; 
    cursor: pointer;
    display: inline-block;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }
		
  .item2 { 
    cursor: pointer;
    width: 30px;
    
    display: inline-block;
  }

  .item3 { 
    cursor: pointer;
    width: 30px;
    
    display: inline-block;
  }

 

  .menu-header-icons {
    display:inline-flex;
    vertical-align:top;

    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }
			
@media only screen and (max-width: 320px) {
  .image-header > img{ 
    /* flex:1 1 auto; */
   
    margin-top: 4px;
    height: 40px;
    width: 200px;
  }
}

/* Container for flexboxes: For the buttons in selecting options*/
.rowButtonOptions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  clear: both;
  padding-bottom:8px;
}

/* Create four equal columns */
.columnButtonOptions {
  flex: 50%;
  padding: 2px;
}
.columnButtonOptions div {
  text-align: center;

  color: #ffffff;
  /*font-size: 16px;*/
  box-shadow: 5px 10px 8px #888888;
  border: 0px #276ecb solid;
  padding: 8px 10px;
  background-color: #23b241;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  word-wrap: break-word;
  border: 1px solid #ccc;
  line-height: 25px;
  height: 100%;
  padding: 4px;
}
